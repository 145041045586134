import { Box, Button, Flex, Typography } from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { removeFromCart } from "app-store-v2/header/actions"
import { ShadowBox, ItemAction } from "areas/cart/components"
import { removeLineItem } from "areas/cart/store/actions"
import { openModal } from "components/modal"
import { isTestMode, IS_JEWLR, jewlr_t } from "helpers/application"

import {
  CardProductImage,
  CardProductImageContainer,
  OutOfStockLabel,
} from "../components"
import GiftOptions from "../gift-wrap/gift-options"
import Price from "../jewlr/price"
import LineItemShipping from "../line-item-shipping"
import WarrantyAddon from "../warranty/warranty-addon"

import Addon from "./addon"
import LineItemInfo from "./line-item-info"

const InstantGiftBtn = styled(Button).attrs({
  color: "primary",
  my: 1,
  underline: true,
  variant: "text",
})`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.03em;
  line-height: 1;
`

const InternalLineItemsDesktop = ({ item, removeAddonItem, removeItem }) => {
  const [showRemoveItemDialog, toggleRemoveItemDialog] = useState(false)
  const outOfStock = !item.in_stock

  return (
    <>
      <ShadowBox pb={1}>
        <Flex gap="10px" mb="10px">
          <CardProductImageContainer outOfStock={outOfStock}>
            <CardProductImage
              alt={item.title}
              data-cy={`cart-line-item-img-${item.style_code}`}
              data-sku={item.sku}
              src={item.image_url}
              title={item.title}
            />
            {outOfStock && <OutOfStockLabel>Out Of Stock</OutOfStockLabel>}
          </CardProductImageContainer>
          <LineItemInfo
            item={item}
            toggleRemoveItemDialog={toggleRemoveItemDialog}
          />

          <Box ml="auto" textAlign="right">
            {item.in_stock ? (
              <>
                <Price dataCyType="desktop" item={item} />
                <LineItemShipping item={item} />
                {!item.reject_addons && IS_JEWLR() && (
                  <InstantGiftBtn
                    onClick={() => {
                      openModal(`#instant-gift-preview-${item.id}`)
                      if (!isTestMode) {
                        dataLayer.push({
                          event: "clicked-need-it-now-cart",
                        })
                      }
                    }}
                  >
                    Need it now? Create an Instant Gift!
                  </InstantGiftBtn>
                )}
              </>
            ) : (
              <ItemAction
                data-cy={`cart-remove-btn-${item.style_code}`}
                fontWeight="500"
                onClick={() => toggleRemoveItemDialog(true)}
              >
                Remove from {jewlr_t("Cart")}
              </ItemAction>
            )}
          </Box>
        </Flex>

        {item.in_stock && IS_JEWLR() && (
          <>
            <Addon
              boxAddon={item.addons.find((a) => a.addon_type === "BOX_PRINT")}
              item={item}
              removeAddonItem={removeAddonItem}
            />
            {!item.reject_addons && <GiftOptions item={item} />}
            {!item.reject_addons && sl.config.enable_extend_warranty && (
              <WarrantyAddon item={item} />
            )}
          </>
        )}
      </ShadowBox>
      <Confirmation
        cancelText="Cancel"
        confirmText="Yes"
        modalType="popup"
        onCancel={() => toggleRemoveItemDialog(false)}
        onConfirm={() => {
          removeItem(item.id)
          toggleRemoveItemDialog(false)
        }}
        render={() => (
          <Typography px={3}>
            Are you sure you want to remove this item from your{" "}
            {jewlr_t("Cart")}?
          </Typography>
        )}
        show={showRemoveItemDialog}
        title="Remove Item"
        variant="squircle"
      />
    </>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeAddonItem: (addonId) => {
      dispatch(removeFromCart(addonId, true))
    },
    removeItem: (itemId) => {
      dispatch(removeLineItem(itemId, "product"))
    },
  }
}

InternalLineItemsDesktop.propTypes = {
  item: PropTypes.object,
  removeAddonItem: PropTypes.func,
  removeItem: PropTypes.func,
}

const LineItemsDesktop = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalLineItemsDesktop)

export default LineItemsDesktop
