import {
  Box,
  Flashbar,
  Flex,
  Image,
  MediaBox,
  Shimmer,
} from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { jewlr_t, isTestMode, retry, IS_JEWLR } from "helpers/application"
import { freeGiftPromoEnabled } from "helpers/holiday"
import useWindowDimensions from "helpers/window-dimensions"

import CartLoading from "./cart-loading"
import CartTitle from "./cart-title"
import { MainContainer, SidePanelWrapper } from "./components"
import EmptyCart from "./empty-cart"
import AddonDesktop from "./exclusive-addons/addon-desktop"
import ExclusiveAddonsDesktop from "./exclusive-addons/desktop"
import ExclusiveAddons from "./exclusive-addons/mobile"
import Footer from "./footer"
import FreeGiftsDesktop from "./free-gifts/desktop"
import FreeGifts from "./free-gifts/mobile"
import fetchCart from "./hoc/fetch-cart"
import LineItems from "./line-items"
import OutOfStockBanner from "./out-of-stock-banner"
import PromoCode from "./promo-code"
import Promos from "./promos"
import SidePanel from "./side-panel"

const DetailModal = loadable(
  () => retry(() => import("./exclusive-addons/detail-modal")),
  { ssr: false }
)
const ErrorModal = loadable(
  () => retry(() => import("areas/checkout/checkout/modal/error-modal")),
  { ssr: false }
)
const InstantGiftPreviewModal = loadable(
  () => retry(() => import("./line-items/instant-gift-preview")),
  { ssr: false }
)

const TitleContainer = styled(Flex).attrs((props) => ({
  bg: { _: props.theme.colors.grey[4], tablet: "white" },
  contain: true,
  flexDirection: "column",
  mb: { _: 0, tablet: "12px" },
  pt: { _: "10px", tablet: 0 },
  px: 2,
}))``

const CartContainer = styled(Box).attrs((props) => ({
  bg: { _: props.theme.colors.grey[4], tablet: "white" },
  contain: true,
  mb: { _: 2, tablet: 0 },
  pt: { _: "30px", tablet: 0 },
  px: 2,
}))``

const PromoShimmer = styled(Shimmer).attrs({
  borderRadius: "10px",
  height: "75px",
  mx: "auto",
  my: 4,
  width: { _: "100%", tablet: "740px" },
})``

const InternalCart = ({ cartData, cartLoaded, couponApplied }) => {
  const [showAddonModal, toggleAddonModal] = useState(false)
  const [addonInModal, setAddonInModal] = useState({})
  const [disableActions, toggleDisableActions] = useState({
    action: "",
    id: "",
  })
  const refs = {
    cartSummaryRef: useRef(),
    checkoutBtnRef: useRef(),
    desktopSummaryRef: useRef(),
  }
  const { isMobile } = useWindowDimensions()

  const freeGiftPromoApplied = () =>
    couponApplied?.toUpperCase() ===
    sl.config.free_gift_promo?.code?.toUpperCase()

  useEffect(() => {
    if (!isTestMode) {
      dataLayer.push({
        event: "viewed-cart",
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window.Beacon !== "undefined") {
      window.Beacon("config", { hideFABOnMobile: true })
    }
    return () => {
      if (typeof window.Beacon !== "undefined") {
        window.Beacon("config", { hideFABOnMobile: false })
      }
    }
  }, [isMobile])

  if (!cartLoaded) {
    return (
      <div>
        <TitleContainer>
          <CartTitle cartData={cartData} refs={refs} />
          <Box order={{ tablet: -1 }}>
            <PromoShimmer />
          </Box>
        </TitleContainer>
        <CartContainer>
          <CartLoading />
        </CartContainer>
      </div>
    )
  }

  if (cartData.line_items.length == 0) {
    return <EmptyCart />
  }

  const errorText = cartData.err_msg
  return (
    <>
      <TitleContainer>
        <CartTitle cartData={cartData} refs={refs} />
        {cartData.summary.has_out_of_stock_item && (
          <Box order={{ tablet: -1 }}>
            <OutOfStockBanner />
          </Box>
        )}
        <Box order={{ tablet: -2 }}>
          <Promos refs={refs} />
        </Box>
      </TitleContainer>
      <CartContainer>
        {errorText && <Flashbar message={errorText} type="error" />}
        <Flex
          flexDirection={{ _: "column", desktop: "row" }}
          gap={{ _: 5, desktop: 2 }}
        >
          <MainContainer>
            {freeGiftPromoEnabled() && (
              <>
                {freeGiftPromoApplied() && <FreeGiftsDesktop />}
                <MediaBox lessThan="tablet" mb={3} mt="-20px">
                  <PromoCode promo />
                  {freeGiftPromoApplied() && <FreeGifts />}
                </MediaBox>
              </>
            )}
            <LineItems line_items={cartData.line_items} />
            {/* <FreeGiftsDesktop />
            <FreeGifts /> */}
            <AddonDesktop exclusiveAddonsRef={refs.exclusiveAddonsRef} />
            {IS_JEWLR() && (
              <ExclusiveAddons
                disableActions={disableActions}
                setAddonInModal={setAddonInModal}
                toggleAddonModal={toggleAddonModal}
                toggleDisableActions={toggleDisableActions}
              />
            )}
            {sl.config.env !== "production" && IS_JEWLR() && (
              <MediaBox lessThan="tablet">
                <Link data-lc="cart" to="/">
                  <Image
                    alt={`${jewlr_t("terms.Personalize")} The Perfect Gift`}
                    ratio="672 / 569"
                    src={require("images/cart/ad-block.jpg")}
                  />
                </Link>
              </MediaBox>
            )}
          </MainContainer>
          <SidePanelWrapper>
            <SidePanel refs={refs} />
          </SidePanelWrapper>
        </Flex>
        {IS_JEWLR() && (
          <ExclusiveAddonsDesktop
            disableActions={disableActions}
            refs={refs}
            setAddonInModal={setAddonInModal}
            toggleAddonModal={toggleAddonModal}
            toggleDisableActions={toggleDisableActions}
          />
        )}
      </CartContainer>
      <Footer />
      {typeof window !== "undefined" && (
        <Suspense fallback={<div />}>
          <InstantGiftPreviewModal />
          <ErrorModal />
          <DetailModal
            addon={addonInModal}
            disableActions={disableActions}
            show={showAddonModal}
            toggleAddonModal={toggleAddonModal}
            toggleDisableActions={toggleDisableActions}
          />
        </Suspense>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartData: state.cart,
    cartLoaded: state.cart.cartLoaded,
    couponApplied: state.cart.summary?.promo_code?.promo_code_applied,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalCart.propTypes = {
  cartData: PropTypes.object,
  cartLoaded: PropTypes.bool,
  couponApplied: PropTypes.string,
}

const Cart = connect(mapStateToProps, mapDispatchToProps)(InternalCart)

export default fetchCart(Cart)
