import { faHand } from "@jewlr/pro-light-svg-icons/faHand"
import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-regular-svg-icons/faChevronUp"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  Image,
  Typography,
  FontAwesomeIcon,
} from "@jewlr/storybook/core"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  DetailBtn,
  DetailText,
  ItemTitle,
  ShadowBox,
  RetailPrice,
} from "areas/cart/components"
import { addAddon } from "areas/cart/store/actions"
import { freeGiftPromoEnabled } from "helpers/holiday"
import { to_currency } from "helpers/number"
import { useNavigation } from "helpers/use-navigation"

const GiftButton = styled(Button).attrs((props) => ({
  border: "2px solid",
  borderColor: props.selected ? "selected" : "transparent",
  borderRadius: "4px",
  variant: "text",
  ...props,
}))`
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.selected};
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) =>
    props.selected &&
    `
    position: relative;
    &:after {
      bottom: -9px;
      content: url(${sl.config.jewlr_asset_url}images/icons/jewlr4-checkmark.svg);
      display: block;
      height: 22px;
      left: 50%;
      position: absolute;
      transform: scale(0.8) translateX(-50%);
      width: 22px;
    }
  `}
`

const GiftImg = styled(Image).attrs((props) => ({
  height: "68",
  width: "68",
  ...props,
}))``

const FreeGiftItem = ({ gift, handleClick, ...props }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <GiftButton
      aria-label={gift.title}
      data-cy={`cart-freegift-desktop-${gift.gift_code}`}
      onClick={handleClick}
      role="radio"
      {...keyboardProps}
      {...props}
    >
      <GiftImg alt={gift.title} src={gift.image_url} title={gift.title} />
    </GiftButton>
  )
}

FreeGiftItem.propTypes = {
  gift: PropTypes.object,
  handleClick: PropTypes.func,
}

const NoGiftItem = ({ handleClick, ...props }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <GiftButton
      aria-label="No Gift"
      bg="#f8f8f8"
      data-cy="cart-freegift-desktop-none"
      onClick={handleClick}
      role="radio"
      {...keyboardProps}
      {...props}
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        height="68px"
        justifyContent="center"
        width="68px"
      >
        <FontAwesomeIcon
          color="grey.30"
          fontSize="20px"
          icon={faHand}
          mb="6px"
        />
        <Typography fontSize="14px" letterSpacing="-0.02em" lineHeight="18px">
          No Thanks
        </Typography>
      </Flex>
    </GiftButton>
  )
}

NoGiftItem.propTypes = {
  handleClick: PropTypes.func,
}

const InternalFreeGifts = ({ chooseGift, freeGifts, selectedFreeGift }) => {
  if (!freeGifts?.length) return null

  const [selectedGift, setGift] = useState(selectedFreeGift || freeGifts[0])
  const [showGiftDetails, toggleGiftDetails] = useState(false)

  useEffect(() => {
    if (
      selectedGift &&
      selectedFreeGift &&
      selectedGift.retail_price !== selectedFreeGift.retail_price
    ) {
      setGift(selectedFreeGift)
    }
  }, [selectedFreeGift])

  return (
    <ShadowBox greaterThanOrEqual="tablet">
      <Flex gap="10px" mx={0}>
        <Image
          alt={selectedGift.title}
          data-cy="cart-freegift-selected-img-desktop"
          height="128px"
          src={selectedGift.image_url}
          title={selectedGift.title}
          width="128px"
        />
        <Box flex="1">
          <ItemTitle
            color="greenDarker"
            fontWeight="400"
            id="cart-free-gift-title-desktop"
            mb="3px"
          >
            Choose Your FREE GIFT!
          </ItemTitle>
          <ItemTitle fontWeight="600" lineHeight="1.24" mb={2}>
            {selectedGift.title}
          </ItemTitle>
          <DetailBtn
            aria-controls="cart-free-gift-details-desktop"
            aria-expanded={showGiftDetails}
            fontSize="12px"
            fontWeight="600"
            lineHeight="1.24"
            mt="15px"
            onClick={() => toggleGiftDetails(!showGiftDetails)}
          >
            View Item Details{" "}
            <FontAwesomeIcon
              icon={showGiftDetails ? faChevronUp : faChevronDown}
              style={{ verticalAlign: "text-bottom" }}
              width="8px"
            />
          </DetailBtn>
          <Expand
            id="cart-free-gift-details-desktop"
            mb="12px"
            show={showGiftDetails}
          >
            <DetailText>{selectedGift.description}</DetailText>
            {freeGiftPromoEnabled() && (
              <>
                <br />
                <DetailText italic>While supplies last.</DetailText>
              </>
            )}
          </Expand>
          <FocusScope>
            <Flex
              aria-label="Choose your free gift"
              data-cy="cart-freegift-category-desktop"
              mt="10px"
              role="radiogroup"
            >
              {freeGifts.map((gift) => {
                const selected =
                  (selectedFreeGift?.addon_code ||
                    selectedFreeGift?.gift_code) === gift.gift_code
                return (
                  <FreeGiftItem
                    aria-selected={selected}
                    gift={gift}
                    handleClick={() => {
                      setGift(gift)
                      chooseGift(gift.gift_code)
                    }}
                    key={`desktop-freegift-${gift.gift_code}`}
                    selected={selected}
                    tabIndex={selected ? 0 : -1}
                  />
                )
              })}
            </Flex>
          </FocusScope>
        </Box>
        <Box textAlign="right">
          <Typography color="greenDarker" fontSize={32} fontWeight="300">
            FREE
          </Typography>
          {freeGiftPromoEnabled() ? (
            <RetailPrice>
              {to_currency(sl.config.free_gift_promo?.retail_value || 0)} VALUE
            </RetailPrice>
          ) : (
            <RetailPrice>
              RETAIL VALUE {to_currency(selectedGift.retail_price)}
            </RetailPrice>
          )}
        </Box>
      </Flex>
    </ShadowBox>
  )
}

const mapStateToProps = (state) => {
  return {
    freeGifts: state.cart.free_gift_offers,
    selectedFreeGift: state.cart.addons.find(
      (addon) => addon.addon_type == "GIFT"
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    chooseGift: (addon_code) => {
      // Desktop Cart select gift
      dispatch(addAddon(addon_code, "GIFT", "cart_desktop"))
    },
    dispatch,
  }
}

InternalFreeGifts.propTypes = {
  chooseGift: PropTypes.func,
  freeGifts: PropTypes.array,
  selectedFreeGift: PropTypes.object,
}

const DesktopFreeGifts = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalFreeGifts)

export default DesktopFreeGifts
