import { faMinus } from "@jewlr/pro-regular-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-regular-svg-icons/faPlus"
import { faTimesCircle } from "@jewlr/pro-regular-svg-icons/faTimesCircle"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  Typography,
  FontAwesomeIcon,
} from "@jewlr/storybook/core"
import FloatInput from "@jewlr/storybook/form/float-input"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { applyCoupon, removeCoupon } from "areas/cart/store/actions"
import { IS_JEWLR } from "helpers/application"
import { freeGiftPromoEnabled } from "helpers/holiday"

const Container = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[30]};
  border-top: 1px solid ${(props) => props.theme.colors.grey[30]};
  padding: 16px 0;
  ${(props) => props.theme.mediaQueries.tablet`
    border: none;
    padding: 0;
  `}
  ${(props) =>
    props.promo &&
    `
    background: ${props.theme.colors.white};
    border: 1px solid ${props.theme.colors.grey[20]};
    border-radius: 5px;
    padding: 16px 12px;
  `}
`

const AdjustmentToggle = styled(Button).attrs((props) => ({
  alignItems: "center",
  justifyContent: "space-between",
  variant: "text",
  width: "100%",
  ...props,
}))``

const Heading = styled(Typography).attrs({
  color: "primary",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
})``

const ApplyButton = styled(Button).attrs({
  alignItems: "center",
  bg: "primary",
  borderRadius: "5px",
  color: "white",
  display: "flex",
  height: "44px",
  justifyContent: "center",
  width: "72px",
})`
  &:disabled {
    background-color: #757575;
  }
`

const AppliedPromoCode = styled(Flex).attrs({
  alignItems: "center",
  bg: "rgba(0, 136, 0, 0.05)",
  borderRadius: "3px",
  flex: 1,
  fontSize: 14,
  height: "33px",
  px: "12px",
})``

const InternalPromoCode = ({
  applyCoupon,
  couponApplied,
  couponDescription,
  couponRemove,
  promo,
}) => {
  const promoInputRef = useRef()
  const [couponValue, setCouponValue] = useState(couponApplied || "")
  const [applying, setApplying] = useState(false)
  const [couponError, setCouponError] = useState("")
  const [showPromoCode, togglePromoCode] = useState(
    IS_JEWLR() ? couponApplied !== undefined : !couponApplied
  )

  const handleApply = () => {
    setCouponError("")
    !applying &&
      applyCoupon(
        couponValue,
        promoInputRef.current,
        setApplying,
        setCouponError
      )
    setApplying(true)
  }

  const freeGiftPromoApplied = () =>
    freeGiftPromoEnabled() &&
    couponApplied?.toUpperCase() ===
      sl.config.free_gift_promo?.code?.toUpperCase()

  return (
    <Container promo={promo}>
      <AdjustmentToggle
        aria-controls="cart-promo-code-panel"
        aria-expanded={showPromoCode}
        data-cy="cart-promo-code-toggle"
        onClick={() => togglePromoCode(!showPromoCode)}
      >
        <Heading>Add Promo Code</Heading>
        <FontAwesomeIcon
          color="primary"
          fontSize="12px"
          icon={showPromoCode ? faMinus : faPlus}
        />
      </AdjustmentToggle>
      <Expand id="cart-promo-code-panel" show={showPromoCode}>
        {/* Applied promo codes */}
        <Box mb="12px" mt={3}>
          {couponApplied ? (
            <>
              <Flex alignItems="center" justifyContent="space-between">
                <AppliedPromoCode>
                  <Typography fontWeight={700}>{couponApplied}</Typography>{" "}
                  &ndash; Code Applied
                </AppliedPromoCode>
                <Button
                  aria-label="Remove applied promo code"
                  data-cy="cart-promo-code-remove"
                  ml="19px"
                  onClick={couponRemove}
                  variant="text"
                >
                  <FontAwesomeIcon
                    color="primary"
                    fontSize="16px"
                    icon={faTimesCircle}
                  />
                </Button>
              </Flex>
              {couponDescription && (
                <Typography
                  color="grey.65"
                  fontSize="12px"
                  lineHeight="12px"
                  mt={1}
                  {...(freeGiftPromoApplied() && {
                    bold: true,
                    color: "greenDarker",
                    fontSize: "14px",
                  })}
                >
                  {couponDescription}
                </Typography>
              )}
            </>
          ) : (
            <>
              {/* Apply promo codes */}
              <Flex gap="12px" justifyContent="space-between" width="100%">
                <Box flex="1">
                  <FloatInput
                    data-cy="cart-promo-code-input"
                    inputRef={promoInputRef}
                    label="Enter Promo Code"
                    onChange={(e) => setCouponValue(e.target.value)}
                    onReturn={handleApply}
                    value={couponValue}
                    variant="checkout"
                  />
                </Box>
                <ApplyButton
                  data-cy="cart-promo-code-apply-btn"
                  disabled={!couponValue}
                  loading={applying}
                  onClick={handleApply}
                >
                  {!applying && "Apply"}
                </ApplyButton>
              </Flex>
              {couponError && (
                <Typography
                  color="error"
                  fontSize="12px"
                  lineHeight="12px"
                  margin="5px 0 0"
                >
                  {couponError}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Expand>
    </Container>
  )
}

InternalPromoCode.propTypes = {
  applyCoupon: PropTypes.func,
  couponApplied: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  couponDescription: PropTypes.string,
  couponRemove: PropTypes.func,
  promo: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    couponApplied: state.cart.summary?.promo_code?.promo_code_applied,
    couponDescription:
      state.cart.summary?.promo_code?.promo_code_applied_description,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applyCoupon: (value, promoInputRef, setApplying, setCouponError) => {
      dispatch(applyCoupon(value, promoInputRef, setApplying, setCouponError))
    },
    couponRemove: () => {
      dispatch(removeCoupon())
    },
  }
}

const PromoCode = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalPromoCode)

export default PromoCode
